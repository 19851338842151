/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'
import globals from '../../constants/global.constant'
import styles from './Layout.module.scss'

import {
  TextField,
  Button,
  CircularProgress,
  Autocomplete,
} from '@mui/material'
import moment from 'moment-timezone'

export const Profile = ({
  userInfo,
  handleChange,
  handleSelectCalendar,
  handleSelectNotification,
  handleSelectTimeZone,
  handleSave,
  handleDisconnect,
  errorUserInfo,
  isLoadingUpdateUser,
  muaMobile,
}) => {
  const {
    LastName,
    FirstName,
    Calendar,
    TimeZone,
    IsActiveNotification,
    Email,
  } = userInfo

  const disableOutlook =
    Email !== 'isamuor90@gmail.com' &&
    Email !== 'm.parra@fraktalsoftware.com' &&
    Email !== 'mateopc12@gmail.com' &&
    Email !== 'julius@wemua.com' &&
    Email !== 'jeff@wemua.com' &&
    Email !== 'carolina@wemua.com' &&
    Email !== 'jp@wemua.com' &&
    Email !== 'julius@aina.studio' &&
    Email !== 'anna@wemua.com' &&
    Email !== 'jgeis@aina.studio'

  const calendarType = {
    Outlook: '2',
    Google: '1',
  }

  return (
    <div className={styles.wrapperProfileEdition}>
      <div className={styles.sectionTitle}>{`My Profile`}</div>
      <div className={styles.textFields}>
        <div className={styles.fieldsLabel}>{`First Name`}</div>
        <TextField
          autoComplete="off"
          id="FirstName"
          onChange={handleChange}
          value={FirstName}
          error={errorUserInfo.FirstName.error}
          sx={{
            marginBottom: '25px',
            width: muaMobile ? '300px' : '200px',

            '& .MuiOutlinedInput-input': {
              paddingLeft: '6px',
            },
          }}
        />
        <div className={styles.fieldsLabel}>{`Last Name`}</div>
        <TextField
          autoComplete="off"
          id="LastName"
          onChange={handleChange}
          value={LastName}
          error={errorUserInfo.LastName.error}
          sx={{
            marginBottom: '25px',
            width: muaMobile ? '300px' : '200px',

            '& .MuiOutlinedInput-input': {
              paddingLeft: '6px',
            },
          }}
        />
        <div className={styles.fieldsLabel}>{`Location (Timezone)`}</div>
        <Autocomplete
          id="Timezones"
          value={
            TimeZone?.Name ? { id: TimeZone.Name, label: TimeZone.Name } : null
          }
          disablePortal
          disableClearable
          noOptionsText="No options"
          componentsProps={{
            paper: {
              sx: {
                background: '#14271F',
              },
            },
          }}
          sx={{
            width: muaMobile ? '300px' : '200px',

            '& + .MuiAutocomplete-popper .MuiAutocomplete-option': {
              color: '#F7F7F7 !important',
              background: '#14271F',
            },
          }}
          onChange={(event, value) => handleSelectTimeZone(value)}
          getOptionLabel={(option) => `${option.label}`}
          options={moment.tz.names().map((ele) => ({ id: ele, label: ele }))}
          renderInput={(params) => (
            <TextField
              {...params}
              sx={{
                marginBottom: '25px',
                width: muaMobile ? '300px' : '200px',
              }}
              placeholder={'Select time zone'}
              InputProps={{
                ...params.InputProps,
              }}
            />
          )}
        />

        <div className={styles.fieldsLabel}>{`Email Notifications`}</div>
        <Autocomplete
          id="Notifications"
          value={{
            id: IsActiveNotification,
            label: IsActiveNotification ? 'ON' : 'OFF',
          }}
          disablePortal
          disableClearable
          noOptionsText="No options"
          componentsProps={{
            paper: {
              sx: {
                background: '#14271F',
              },
            },
          }}
          sx={{
            width: muaMobile ? '300px' : '200px',

            '& + .MuiAutocomplete-popper .MuiAutocomplete-option': {
              color: '#F7F7F7 !important',
              background: '#14271F',
            },
          }}
          onChange={(event, value) => handleSelectNotification(value.id)}
          getOptionLabel={(option) => `${option.label}`}
          options={[
            { Name: 'ON', Value: true },
            { Name: 'OFF', Value: false },
          ].map((ele) => ({ id: ele.Value, label: ele.Name }))}
          renderInput={(params) => (
            <TextField
              {...params}
              sx={{
                marginBottom: '25px',
                width: muaMobile ? '300px' : '200px',
              }}
              InputProps={{
                ...params.InputProps,
              }}
            />
          )}
        />
      </div>

      {isLoadingUpdateUser ? (
        <div className={styles.saveButtonWrapper}>
          <CircularProgress
            size="20px"
            color="light"
            sx={{ marginTop: '14px' }}
          />
        </div>
      ) : (
        <div className={styles.saveButtonWrapper}>
          <Button onClick={handleSave} className={styles.saveButton}>
            <p>Save changes</p>
          </Button>
        </div>
      )}
      <div className={styles.calendarIntegrationButtons}>
        <div className={styles.sectionTitle}>{`Integrations`}</div>
        <div
          className={styles.sectionHelper}
        >{`Connect MUA with one these available integrations.`}</div>
        <div className={styles.googleButton}>
          <button
            onClick={() => handleSelectCalendar('1')}
            style={
              calendarType[Calendar] === '1' || calendarType[Calendar] === '2'
                ? {
                    border: 'none',
                    background: 'transparent',
                    textTransform: 'none',
                    pointerEvents: 'none',
                    cursor: 'not-allowed',
                  }
                : {
                    border: 'none',
                    background: 'transparent',
                    textTransform: 'none',
                    cursor: 'pointer',
                  }
            }
          >
            <img
              width={'215px'}
              height={'41px'}
              alt="Google sign-in"
              src={
                calendarType[Calendar] === '1' || calendarType[Calendar] === '2'
                  ? `${globals.imageBasePath}btn_google_signin_light_disabled_web.png`
                  : `${globals.logosBasePath}web_light_sq_SI.svg`
              }
            />
          </button>
          {!disableOutlook && (
            <button
              onClick={() => handleSelectCalendar('2')}
              style={
                calendarType[Calendar] === '2' || calendarType[Calendar] === '1'
                  ? {
                      border: 'none',
                      background: 'transparent',
                      textTransform: 'none',
                      pointerEvents: 'none',
                      cursor: 'not-allowed',
                    }
                  : {
                      border: 'none',
                      background: 'transparent',
                      textTransform: 'none',
                      cursor: 'pointer',
                    }
              }
            >
              <img
                width={'215px'}
                height={'41px'}
                alt="Microsoft sign-in"
                src={
                  calendarType[Calendar] === '2' ||
                  calendarType[Calendar] === '1'
                    ? `${globals.imageBasePath}ms-symbollockup_signin_light_disabled.png`
                    : `${globals.logosBasePath}ms-symbollockup_signin_light.svg`
                }
              />
            </button>
          )}
          {!disableOutlook && (
            <div className={styles.saveButtonWrapper}>
              <Button
                onClick={handleDisconnect}
                className={styles.saveButton}
                disabled={!Calendar}
              >
                <p>Disconnect calendar</p>
              </Button>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
